@import 'palette';
@import 'fonts';
@import 'spacing';

.main {
    align-items: center;
    background-color: $light-color;
    display: flex;
    flex-direction: column;

    & > *:nth-child(odd) {
        background-color: $neutral-color;
    }
}

.menu {
    padding: $spacing-1;
    width: 100%;
}

.nutrition-link {
    display: block;
    font-family: $ff-interstate-regular;
    font-size: $fs--1;
    margin-top: $spacing-2;
    text-align: center;
}

@media screen and (min-width: 640px) {
    .nutrition-link {
        font-size: $fs-2;
    }
}
