@import 'mixins/modules';
@import 'palette';
@import 'spacing';

$content-max-width-desktop: 420px;
$content-max-width-desktop-slim: 460px;
$hero-height-desktop: 680px;
$hero-height-mobile: 628px;
$hero-height-medium-desktop: 500px;
$hero-height-medium-mobile: 525px;
$hero-height-slim-desktop: 385px;
$hero-height-slim-mobile: 360px;

.standard-hero {
    @include webpage-content-module;
    @include webpage-content-light-text;
    flex-direction: column;
    height: $hero-height-mobile;

    section {
        footer a {
            color: inherit;
            text-decoration: underline;
        }

        footer div {
            position: relative;
        }
    }

    &--medium {
        height: $hero-height-medium-mobile;
    }
    &--slim {
        height: $hero-height-slim-mobile;
    }

    footer {
        width: 100%;
    }

    /* pull content left on desktop */
    @media screen and (min-width: 640px) {
        height: $hero-height-desktop;
        width: 100%;

        section {
            align-items: flex-start;
            padding-left: $spacing-6;
            padding-right: $spacing-6;
            text-align: left;
        }

        .content {
            h2 {
                text-align: left;
            }

            &.center {
                max-width: 100%;

                .standard-hero-nav {
                    max-width: $content-max-width-desktop;
                }
            }
        }

        &--medium {
            height: $hero-height-medium-desktop;
        }
        &--slim {
            height: $hero-height-slim-desktop;
            .content {
                max-width: $content-max-width-desktop-slim;

                &.center .standard-hero-nav {
                    max-width: $content-max-width-desktop-slim;
                }
            }
        }
    }

    @media screen and (min-width: 768px) {
        section footer div > p {
            display: inline;
        }
    }
}

:global(.gtamerica) {
    &.standard-hero {
        @include standard-module-gtamerica;
    }
}

/* for positioning hero title / description / ctas as a group */
.content {
    max-height: 100%;

    &:last-child {
        margin-bottom: $spacing-6;
    }

    > div {
        margin-bottom: $spacing-3;
        max-height: 100%;

        &:last-child {
            margin-bottom: 0;
        }

        p + p {
            margin-top: $spacing-00;
        }
    }

    @media screen and (min-width: 640px) {
        flex-grow: 2;
        margin-bottom: 0;
        margin-top: $spacing-13;
        max-width: $content-max-width-desktop;

        .standard-hero--slim & {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.center {
            align-self: center;
            text-align: center;

            h1 {
                text-align: center;
            }
        }

        &.right {
            align-self: flex-end;
        }
    }
}

/* class passed to FlexGridLayout component */
.standard-hero-nav {
    @include webpage-content-nav;
    justify-content: center;
    width: 100%;

    &.single a {
        display: inline-block;
        flex-basis: unset;
        min-width: 50%;
        width: auto;
    }

    @media screen and (min-width: 640px) {
        justify-content: flex-start;
        width: 100%;

        &.single a {
            min-width: $cta-min-width-desktop;
        }

        &.center {
            justify-content: center;
        }
    }

    &.nav-graphic {
        flex-basis: unset;

        a {
            flex-basis: unset;
        }
    }
}
